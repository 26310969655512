import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import { HeaderProvider } from "./src/context/header-context"
import { CookiesProvider } from "react-cookie"
import "lazysizes"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  <CookiesProvider>
    <HeaderProvider>
      <StoreProvider>{element}</StoreProvider>
    </HeaderProvider>
  </CookiesProvider>
)

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   const currentPosition = getSavedScrollPosition(location);

//   window.scrollTo(...(currentPosition || [0, 0]));

//   return false;
// };
