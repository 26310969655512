import * as React from "react";

const defaultValues = {
  showCart: false,
  showCurrency: false,
  showFilters: false,
};

export const HeaderContext = React.createContext(defaultValues);
const isBrowser = typeof window !== `undefined`;
const sessionStorageKey = `loadOnce`;
const getLoadOnce = isBrowser
  ? JSON.parse(sessionStorage.getItem(sessionStorageKey))
  : false;
export const HeaderProvider = ({ children }) => {
  const [showCart, setCart] = React.useState(false);
  const [showCurrency, setCurrency] = React.useState(false);
  const [showFilters, setFilters] = React.useState(false);

  const [loadOnce, setLoadOnce] = React.useState(getLoadOnce);
  const toggleCart = (value) => {
    setCart(value);
  };
  const toggleCurrency = (value) => {
    setCurrency(value);
  };
  const toggleFilters = (value) => {
    setFilters(value);
  };

  React.useEffect(() => {
    function setLoadvalue() {
      setLoadOnce(true);
      sessionStorage.setItem(sessionStorageKey, true);
    }
    var timer = setTimeout(() => setLoadvalue(), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <HeaderContext.Provider
      value={{
        ...defaultValues,
        showCart,
        showCurrency,
        showFilters,
        toggleCart,
        toggleCurrency,
        toggleFilters,
        loadOnce,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
